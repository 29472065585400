$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 767px;
$value_two: 768px;
$value_three: 991px;
$value_four: 992px;
$value_five: 1199px;
$value_six: 1650px;

$main-color: #ff4800;
$paragraph-color: #848484;
$paragraph-color2: #d6d6d6;
$white-color: #ffffff;
$black-color: #000000;
$sub-black-color: #111111;
$transition: .5s;
$font-size: 14px;

/* Max Width 767px */
@media only #{$media} and ($feature_max : $value_one) {
    body {
        font-size: $font-size;
    }
    p {
        font-size: $font-size;
    }
    .section-title {
        margin-bottom: 40px !important;

        span {
            font-size: 13px;
            text-transform: capitalize;
        }
        h2 {
            font-size: 23px;
            margin: {
                top: 10px;
                bottom: 0;
            }
        }
        .uk-button {
            display: none;
        }
    }

    .header-area {
        padding: {
            top: 15px;
            bottom: 15px;
        }
        &.uk-sticky-fixed {
            padding: {
                top: 15px;
                bottom: 15px;
            }
        }
        #navbar-toggle {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
        }
    }
    .navbar {
        display: none;
    }

    .main-banner {
        height: 100%;
        background-position: left bottom;
        padding: {
            top: 145px;
            bottom: 600px;
        }
    }
    .main-banner-content {
        max-width: unset;

        h1 {
            font-size: 35px;
            line-height: 38px;
            padding: 8px 0;
            margin-bottom: 35px;

            &::before {
                width: 15px;
            }
        }
 
        .video-btn {
            margin-left: 8px;
            font-size: 13px;

            .uk-icon {
                width: 40px;
                margin-right: 3px;
                height: 40px;
                line-height: 40px;  
            }
        }
        .uk-button {
            font-size: 14px;
            padding: 12px 27px;
        }
    }
    .banner-with-form {
        padding-bottom: 565px;

        .uk-container {
            .uk-grid {
                margin-top: 0;
            }
        }
    }
    .main-banner-form {
        padding: 20px;
        margin: {
            left: 0;
            top: 30px;
        }
    }

    .features-area {
        margin-top: -520px;
        background-color: transparent;

        &.uk-dark {
            position: relative;
            z-index: 1;
            background-color: transparent;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                display: block;
                background: $black-color;
                height: 45%;
                z-index: -1;
            }
        }
    }
    .single-features-box {
        margin-top: 20px;
        padding: 25px 20px;

        .icon {
            i::before {
                font-size: 35px;
            }
        }
        h3 {
            font-size: 18px;
        }
        .animation-img {
            img {
                display: none;
            }
        }
    }

    .about-img {
        position: relative;
        top: 0;
        margin-top: 35px;

        .about-img2, .shape-img {
            display: none;
        }
        img {
            width: 100%;
        }
        .about-img1 {
            width: 100%;
        }
        .uk-button {
            right: 0;
            bottom: 1px;
        }
    }
    .about-content {
        .about-text {
            h3 {
                font-size: 17px;
            }
        }
    }

    .services-area {
        padding-bottom: 20px;

        .item {
            margin-bottom: 20px;
        }
    }
    .single-services {
        padding: 30px 30px 30px 88px;
        border-radius: 65px 0 0 65px;

        .icon {
            width: 70px;
            height: 70px;
            line-height: 70px;
            margin-top: -35px;
            left: 5px;

            i::before {
                font-size: 28px;
            }
        }
        h3 {
            font-size: 15px;
        }
        .link-btn {
            margin-top: -10px;

            &::before {
                font-size: 15px;
            }
        }
    }
    .single-services-box {
        padding: 20px;

        .icon {
            i {
                &::before {
                    font-size: 30px;
                }
            }
        }
        h3 {
            font-size: 15px;
        }
        .animation-img {
            img {
                display: none;
            }
        }
    }

    .project-slides {
        left: 0;

        &.owl-theme {
            .owl-dots {
                margin-top: 25px !important;
            }
        }
    }
    .single-project {
        .project-content {
            padding: 25px 15px;

            h3 {
                font-size: 18px;
            }
            ul {
                li {
                    margin: 0 7px;

                    a {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .feedback-img {
        .shape-img {
            display: none;
        }
        .video-btn {
            right: 10px;
            bottom: 10px;
        }
    }
    .feedback-inner {
        margin-top: 30px;
    }
    .testimonials-area {
        padding-bottom: 10px;
    }
    .testimonials-item {
        .quotation-profile {
            .profile-info {
                h3 {
                    font-size: 15px;
                    margin-bottom: 4px;
                }
                span {
                    font-size: 13px;
                }
            }
        }
    }

    .partner-area {
        padding-top: 40px !important;
    }

    .team-area {
        padding-bottom: 10px;
    }
    .team-slides {
        &.owl-theme {
            .owl-dots {
                margin-top: 0 !important;
            }
        }
    }
    .single-team {
        .team-content {
            h3 {
                font-size: 18px;
            }
        }
        &:hover, &:focus {
            .team-social {
                top: 0;
            }
            img {
                top: 60px;
            }
        }
    }

    .subscribe-area {
        text-align: center;

        h3 {
            font-size: 18px;
            margin: 0 0 20px !important;
        }
        form {
            .uk-button {
                position: relative;
                height: 50px;
                margin-top: 20px;
            }
        }
    }

    .single-blog-post {
        .blog-post-content {
            margin: {
                right: 5px;
                left: 5px;
                top: -30px;
            }
            padding: 20px 15px;

            h3 {
                font-size: 18px;
            }
        }
        .blog-post-image {
            a {
                img {
                    width: 100%;
                }
            }
        }
    }
    .blog-slides {
        &.owl-theme {
            .owl-dots {
                margin-top: 0 !important;
            }
        }
    }

    .map-img {
        margin: {
            bottom: 0 !important;
            top: 75px;
        }
        .location {
            a {
                .location-info {
                    left: -58px;
                    width: 130px;
                    padding: 15px 10px;

                    h5 {
                        font-size: 14px;
                    }
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .footer-area {
        padding-top: 70px;

        .footer-shape1, .footer-shape2 {
            display: none;
        }
    }

    .single-footer-widget {
        margin-bottom: 30px !important;

        .logo {
            margin-bottom: 20px;
        }
        h3 {
            font-size: 18px;
        }
    }
    .copyright-area {
        margin-top: 10px;
        text-align: center;

        ul {
            text-align: center;
            margin-top: 10px;
        }
        .back-to-top {
            top: -22px;

            .uk-totop {
                width: 30px;
                line-height: 30px;
                height: 30px;
            }
        }
    }

    .page-title-area {
        padding: {
            top: 120px;
            bottom: 60px;
        }
        h1 {
            font-size: 22px;
        }
        ul {
            margin: 13px 0 0;
        }
    }

    .blog-details {
        .uk-flex {
            display: block;
        }
        .uk-width-1-5 {
            width: 100%;
        }
        .inner {
            .article-img {
                .date {
                    width: 80px;
                    height: 60px;
                    font-size: 15px;
                    padding-top: 13px;
                    line-height: 23px;
                }
            }
            .article-content {
                ul {
                    &.category {
                        li {
                            margin-bottom: 2px;
                        }
                    }
                }
                h3 {
                    font-size: 18px;
                    line-height: 28px;
                }
                .blockquote {
                    padding: 20px;
                    border-width: 3px;

                    p {
                        font-size: 15px;
                    }
                }
            }
            .post-controls-buttons {
                overflow: hidden;

                .item {
                    float: left;
                    width: 50%;

                    .uk-button {
                        font-size: 14px;
                        padding: 10px 25px;
                    }
                }
            }
            .comments-area {
                .comments-title, .comment-reply-title {
                    font-size: 17px;
                }
                ol, ul {
                    li {
                        .comment-body {
                            .reply {
                                position: relative;
                                right: 0;
                                top: 0;
                                margin-top: 15px;
                            }
                        }
                    }
                }
                .comment-respond {
                    .comment-form-comment, .comment-form-author, .comment-form-email, .comment-form-url {
                        input, textarea {
                            width: 90%;
                        }
                    }
                }
            }
        }
        .uk-sidebar {
            margin-top: 40px;
        }
    }

    .uk-sidebar {
        .widget {
            .widget-title {
                font-size: 18px;
            }
            &.widget_recent_entries {
                ul {
                    li {
                        h5 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .services-details {
        .uk-flex {
            display: block;
        }
        .uk-width-1-5 {
            width: 100%;
        }
        .uk-sidebar {
            margin-top: 40px;
        }
    }
    .services-details-desc {
        h3 {
            font-size: 18px;
        }
        .services-features-list {
            li {
                float: unset;
                width: 100%;
            }
        }
        blockquote, .blockquote {
            padding: 20px 15px;

            p {
                font-size: 14px;
            }
        }
        .our-work-benefits {
            .accordion {
                .accordion-title {
                    font-size: 14px;
                }
            }
        }
    }

    .pagination-area {
        margin-top: 10px;
    }

    .project-details {
        .uk-flex {
            display: block;
        }
        .uk-width-1-5 {
            width: 100%;
        }
    }
    .project-details-info {
        padding: 20px;

        ul {
            li {
                font-size: 14px;
            }
        }
    }
    .project-details-desc {
        h3 {
            font-size: 18px;
        }
        blockquote, .blockquote {
            padding: 20px 15px;

            p {
                font-size: 14px;
            }
        }
    }
    .project-next-and-prev {
        overflow: hidden;
        padding: 0;
        box-shadow: unset;

        .item {
            float: left;
            width: 50%;

            .uk-button {
                font-size: 14px;
                padding: 10px 20px;
            }
        }
    }

    /* Home 7, 8, 9 CSS */
    .uk-button {
        font-size: 14px;
        padding: 13px 25px;
    }
    .uk-button-optional {
        font-size: 14px;
        padding: 13px 25px;
    }
    .header-area {
        &.header-style-two {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .banner-content {
        padding: 120px 15px 80px 45px;
        height: auto;
        h1 {
            font-size: 25px;
            margin-top: 0;
        }
        .content {
            margin-top: 0;
        }
    }
    .banner-image {
        img {
            display: block;
        }
    }

    .services-section {
        padding-bottom: 10px;
    }
    .services-box {
        .content {
            h3 {
                font-size: 18px;
            }
        }
    }
    .shape-circle-img1, .shape-circle-img2 {
        display: none;
    }
    .about-image {
        margin-bottom: 30px !important;
    }

    .single-process-box {
        h3 {
            font-size: 18px;
        }
    }
    .feedback-section {
        padding-bottom: 10px;
    }
    .single-feedback-item {
        .client-info {
            h3 {
                font-size: 18px;
            }
        }
    }
    .single-project-box {
        .project-content {
            padding: 25px 20px;
            h3 {
                font-size: 18px;
            }
            .details-btn {
                right: 20px;
                width: 35px;
                height: 35px;
                line-height: 35px;
                i {
                    svg {
                        width: 20px;
                    }
                }
            }
        }
    }

    .single-funfacts {
        margin-top: 15px;
        margin-bottom: 15px !important;
    }
    .contact-form {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }
    .contact-image {
        height: 400px;
        .contact-info {
            right: 0;
            bottom: 0;
            h3 {
                font-size: 20px;
            }
        }
    }
    .process-section {
        .item {
            &:nth-child(1), &:nth-child(3) {
                .single-process-box {
                    margin-top: 0 !important;
                }
            }
        }
    }
    .single-process-box {
        margin-bottom: 30px !important;
    }

    .hero-banner-content {
        margin-top: 0;
        h1 {
            margin-top: 0;
            font-size: 30px;
        }
    }
    .hero-banner {
        padding-top: 120px;
        padding-bottom: 60px;
    }
    .hero-banner-image {
        margin-top: 30px;
    }
    .about-image-two {
        margin-bottom: 30px !important;
    }
    .why-choose-us-image {
        margin-top: 30px;
    }

    .why-choose-us-content {
        padding: 0 15px;
    }
    .single-team-box {
        .content {
            padding: 25px 75px 25px 20px;
            h3 {
                font-size: 18px;
            }
        }
    }
    .feedback-section {
        .section-title {
            h2 {
                margin-left: 0;
            }
        }
    }

    .shape1, .shape2 {
        display: none;
    }
    .corporate-main-banner {
        padding-bottom: 60px;
    }
    .corporate-banner-content {
        padding: 0 15px 60px 50px;
        .content {
            margin-right: auto;
            h1 {
                font-size: 35px;
            }
        }
    }
    .corporate-banner-image {
        img {
            display: block;
        }
    }

    .single-featured-services-box {
        border: 1px solid #eee;
        padding: 20px 10px;
        h3 {
            font-size: 18px;
        }
    }
    .what-we-do-content {
        padding-top: 30px;
        padding-bottom: 60px;
        padding-left: 45px;
        padding-right: 15px;
        .content {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .what-we-do-image {
        img {
            display: block;
        }
    }
    .experience-content {
        padding-top: 0;
        padding-bottom: 40px;
        padding-right: 15px;
        padding-left: 45px;
        .content {
            margin-right: auto;
            .single-experience-box {
                padding-left: 85px;
                .icon {
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                }
            }
        }
    }

    .single-testimonials-box {
        p {
            font-size: 16px;
        }
    }
    .single-pricing-box {
        margin-bottom: 30px !important;
        .pricing-header {
            h3 {
                font-size: 20px;
            }
        }
    }

    .project-area {
        padding-bottom: 10px;
    }
    .single-project-item {
        margin-bottom: 30px !important;
        .content {
            h3 {
                font-size: 18px;
            }
        }
    }

    .single-blog-post-item {
        margin-bottom: 30px !important;
        .post-content {
            h3 {
                font-size: 18px;
            }
        }
    }

    .partner-item {
        margin-bottom: 15px !important;
        margin-top: 30px;
    }
    .blog-area {
        padding-bottom: 10px;
    }

    .newsletter-area {
        padding-top: 50px;
        padding-bottom: 50px;
        h2 {
            font-size: 25px;
        }
    }
    .newsletter-content {
        form {
            .uk-button {
                height: 51px;
                padding: 0 10px;
                font-size: 13px;
            }
            .uk-input {
                font-size: 12px;
            }
        }
    }
    .process-section {
        padding-bottom: 10px;
    }
    /* End Home 7, 8, 9 CSS */
}

/* Min Width 768px to Max Width 991px */
@media only #{$media} and ($feature_min : $value_two) and ($feature_max : $value_three) {
    body {
        font-size: $font-size;
    }
    p {
        font-size: $font-size;
    }
    .section-title {
        h2 {
            font-size: 30px;
        }
    }

    .header-area {
        padding: {
            top: 20px;
            bottom: 20px;
        }
        &.uk-sticky-fixed {
            padding: {
                top: 25px;
                bottom: 25px;
            }
        }
        #navbar-toggle {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
        }
    }
    .navbar {
        display: none;
    }

    .main-banner {
        height: 100%;
        background-position: right bottom;
        padding: {
            top: 200px;
            bottom: 540px;
        }
    }
    .main-banner-content {
        h1 {
            font-size: 45px;
            line-height: 45px;
            padding: 6px 0;

            &::before {
                width: 17px;
            }
        }
        p {
            margin: {
                top: 25px;
                bottom: 25px;
            }
        }
    }
    .banner-with-form {
        padding-bottom: 455px;

        .uk-container {
            .uk-grid {
                margin-top: 0;
            }
        }
    }
    .main-banner-form {
        margin: {
            left: 0;
            top: 30px;
        }
    }

    .features-area {
        background: transparent;
        margin-top: -400px;

        &.uk-dark {
            background-color: transparent;
            position: relative;
            z-index: 1;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                background: $black-color;
                height: 65%;
                z-index: -1;
                display: block;
            }
        }
    }
    .single-features-box {
        margin-top: 20px;

        h3 {
            font-size: 20px;
        }
        .animation-img {
            display: none;
        }
    }

    .about-content {
        .about-text {
            h3 {
                font-size: 18px;
            }
        }
    }
    .about-img {
        position: relative;
        top: 0;
        bottom: 0;
        text-align: center;
        margin-top: 30px;

        .about-img2 {
            display: none;
        }
        .about-img1 {
            width: 100%;
        }
        .uk-button {
            bottom: 0;
        }
    }

    .services-area {
        padding-bottom: 10px;
    }
    .single-services {
        h3 {
            font-size: 16px;
        }
    }
    .single-services-box {
        padding: 30px;

        .icon {
            i {
                &::before {
                    font-size: 35px;
                }
            }
        }
        h3 {
            font-size: 16px;
        }
        .animation-img {
            display: none;
        }
    }
    
    .testimonials-area {
        padding-bottom: 10px;
    }
    .testimonials-item {
        .quotation-profile {
            .profile-info {
                h3 {
                    font-size: 16px;
                }
            }
        }
    }

    .project-slides {
        left: 0;
        position: relative;
    }
    .single-project {
        .project-content {
            h3 {
                font-size: 20px;
            }
        }
    }

    .feedback-img {
        .video-btn {
            right: 0;
        }
    }
    .feedback-inner {
        margin-top: 35px;
    }
    
    .team-area {
        padding-bottom: 10px;
    }
    .team-slides {
        &.owl-theme {
            .owl-dots {
                margin-top: 10px !important;
            }
        }
    }

    .partner-area {
        padding-top: 40px !important;
    }

    .subscribe-area {
        text-align: center;

        h3 {
            font-size: 20px;
            margin: 0 0 20px !important;
        }
        form {
            .uk-button {
                position: relative;
                height: 50px;
                margin-top: 20px;
            }
        }
    }

    .single-blog-post {
        .blog-post-content {
            margin: {
                right: 10px;
                left: 10px;
            }
            h3 {
                font-size: 20px;
            }
        }
    }

    .single-footer-widget {
        margin-bottom: 30px !important;

        h3 {
            font-size: 20px;
        }
    }
    .copyright-area {
        margin-top: 40px;
    }

    .page-title-area {
        padding: {
            top: 135px;
            bottom: 70px;
        }
    }

    .pagination-area {
        margin-top: 20px;
    }

    .blog-details {
        .uk-flex {
            display: block;
        }
        .uk-width-1-5 {
            width: 100%;
        }
        .inner {
            .article-content {
                h3 {
                    font-size: 20px;
                }
            }
            .comments-area {
                .comments-title, .comment-reply-title {
                    font-size: 20px;
                }
                .comment-respond {
                    .comment-form-comment, .comment-form-author, .comment-form-email, .comment-form-url {
                        input, textarea {
                            width: 90%;
                        }
                    }
                }
            }
        }
        .uk-sidebar {
            margin-top: 40px;
        }
    }

    .services-details {
        .uk-flex {
            display: block;
        }
        .uk-width-1-5 {
            width: 100%;
        }
        .uk-sidebar {
            margin-top: 40px;
        }
    }
    .services-details-desc {
        h3 {
            font-size: 20px;
        }
    }

    .project-details {
        .uk-flex {
            display: block;
        }
        .uk-width-1-5 {
            width: 100%;
        }
    }
    .project-details-desc {
        h3 {
            font-size: 20px;
        }
    }

    /* Home 7, 8, 9 CSS */
    .banner-image {
        img {
            display: block;
        }
    }
    .banner-content {
        padding: 80px 0;
        height: auto;
        .content {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .about-image {
        margin-bottom: 50px !important;
    }
    .shape-circle-img1, .shape-circle-img2 {
        display: none;
    }
    .single-funfacts {
        margin-top: 15px;
        margin-bottom: 15px !important;
    }

    .contact-image {
        height: 500px;
        .contact-info {
            right: 40px;
            bottom: 40px;
            h3 {
                font-size: 22px;
            }
        }
    }
    .contact-form {
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;

        .section-title {
            max-width: 100%;
        }
    }

    .hero-banner {
        padding-top: 130px;
    }
    .hero-banner-content {
        margin-top: 0;
        h1 {
            margin-top: 0;
            font-size: 40px;
        }
    }
    .hero-banner-image {
        margin-top: 50px;
    }
    .about-image-two {
        margin-bottom: 40px !important;
    }
    .why-choose-us-content {
        padding-left: 30px;
    }

    .feedback-section {
        .section-title {
            h2 {
                margin-left: 0;
            }
        }
    }

    .corporate-banner-content {
        padding-top: 100px;
        .content {
            margin-right: auto;
        }
    }
    .corporate-banner-image {
        img {
            display: block;
        }
    }

    .section-title {
        margin-bottom: 30px !important;
    }
    .featured-services-area {
        padding-bottom: 30px;
    }
    .single-featured-services-box {
        margin-bottom: 30px !important;
        border: 1px solid #eee;
        padding: 30px 20px;
    }

    .what-we-do-image {
        img {
            display: block;
        }
    }
    .what-we-do-content {
        padding-left: 30px;
        padding-bottom: 0;
        .content {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .experience-image {
        img {
            display: block;
        }
    }
    .experience-content {
        .content {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .single-pricing-box {
        margin-bottom: 30px !important;
    }
    .pricing-area {
        padding-bottom: 10px;
    }

    .project-area {
        padding-bottom: 20px;
    }
    .single-project-item {
        margin-bottom: 30px !important;
    }

    .blog-area  {
        padding-bottom: 20px;
    }
    .single-blog-post-item {
        margin-bottom: 30px !important;
    }
    /* End Home 7, 8, 9 CSS */
}

/* Min Width 992px to Max Width 1199px */
@media only #{$media} and ($feature_min : $value_four) and ($feature_max : $value_five) {
    .about-img {
        position: relative;
        top: 0;

        .about-img2 {
            left: 0;
            bottom: 0;
        }
        .uk-button {
            bottom: 0;
        }
    }

    .main-banner-form {
        margin-top: 0;
    }

    .project-slides {
        left: 0;
    }

    /* Home 7, 8, 9 CSS */
    .header-area {
        &.header-style-two {
            z-index: 999999;

            .uk-navbar-right {
                margin-left: auto;
            }
        }
    }
    .banner-content {
        padding: 50px 20px 50px 50px;
        h1 {
            font-size: 40px;
        }
    }
    .services-box {
        .content {
            padding-left: 15px;
            padding-right: 15px;
            h3 {
                font-size: 18px;
            }
        }
    }
    .single-process-box {
        h3 {
            font-size: 18px;
        }
    }
    .single-project-box {
        .project-content {
            h3 {
                font-size: 18px;
            }
        }
    }
    .single-funfacts {
        h3 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
        }
    }
    .contact-form {
        padding-right: 30px;
    }

    .hero-banner-content {
        margin-top: 0;
        h1 {
            font-size: 40px;
        }
    }
    .why-choose-us-content {
        padding-left: 30px;
    }
    .process-arrow-icon {
        left: 0;
    }
    .single-team-box {
        .content {
            h3 {
                font-size: 18px;
            }
        }
    }

    .corporate-banner-content {
        padding-right: 25px;
        .content {
            max-width: 430px;
            padding-left: 30px;
            h1 {
                font-size: 40px;
            }
        }
    }

    .what-we-do-content {
        padding-left: 30px;
        padding-right: 30px;
    }

    .experience-content {
        padding-right: 30px;
        padding-left: 30px;
        .content {
            padding-left: 30px;
        }
    }
    .single-blog-post-item {
        .post-content {
            h3 {
                font-size: 20px;
            }
        }
    }
    .single-features-box {
        padding: 45px 30px;
    }
    /* End Home 7, 8, 9 CSS */
}

/* Min Width 1650px */
@media only #{$media} and ($feature_min : $value_six) {
    .main-banner {
        background: {
            position: 74%;
        }
    }

    .about-img {
        position: absolute;
        right: 0;
        top: 70px;
        text-align: right;

        .about-img2 {
            position: absolute;
            left: -130px;
            bottom: -30px;
        }
        .shape-img {
            position: absolute;
            left: 115px;
            top: 12%;
        }
        .about-img1 {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {    
    /* Home 7, 8, 9 CSS */
    .header-area {
        &.header-style-two {
            z-index: 999999;

            .uk-navbar-right {
                margin-left: auto;
            }
        }
    }
    .contact-form {
        padding-right: 30px;
    }

    .why-choose-us-content {
        padding-left: 30px;
    }

    .corporate-banner-content {
        padding-left: 30px;
        .content {
            padding-left: 30px;
        }
    }
    .what-we-do-content {
        padding-right: 30px;
    }
    .experience-content {
        padding-left: 30px;
        .content {
            padding-left: 30px;
        }
    }
    /* End Home 7, 8, 9 CSS */
}

@media only screen and (max-width: 991px) {   
    .uk-dropdown {
        display: block;
        position: initial;
    }
}